import React from 'react';
import { Grid } from '@mui/material';
import Logo from './media/logo.png';
import Login from './Login';

const LoginPage = () => {
    return (
        <Grid
            container
            sx={{
                width: '100%',
                minHeight: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'  // Use flexDirection inside sx
            }}
        >
            <Grid item>
                <img src={Logo} alt="logo" style={{width: '50vh',maxWidth: '100%' }} />
            </Grid>
            <Grid item>
                <Login />
            </Grid>
        </Grid>
    );
};

export default LoginPage;
