import { verifySignedMessage } from '@alephium/web3';
import { useConnect, useWallet } from '@alephium/web3-react';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { signMessage } from './services/sign';
import authService from "./services/auth";

const generateNonce = () => Math.random().toString(36).substring(2) + Date.now().toString(36);

const AuthenticatedRoute = () => {
  const { account, signer } = useWallet();
  const { disconnect } = useConnect();
  const [val, setVal] = useState(null);
  const [signature, setSignature] = useState(null);
  const [nonce] = useState(generateNonce());
  const signingStartedRef = useRef(false);

  useEffect(() => {
    if (val !== null) {
     
      if (!val) {
        disconnect();
      } else if (val === true && signature) {
       
        authService.login(account?.address, account?.publicKey, nonce, signature);
      }
    }
  }, [val, disconnect, signature, account, nonce]);

  useEffect(() => {
    const initiateSigning = async () => {
      if (account && !signature && !signingStartedRef.current) {
        signingStartedRef.current = true;
        try {
          await signMessage(nonce, account, signer, setVal, setSignature);
         
        } catch (error) {
          console.error("Signing error", error);
        }
      }
    };

    initiateSigning();
    // Cleanup function to reset the signingStartedRef when the component is unmounted
    return () => {
      signingStartedRef.current = false;
    };
  }, [account, nonce, signer, signature]);

  useEffect(() => {
   
  }, [account, signature]);

  return account ? <Outlet /> : <Navigate to="/login" />;
};

const UnauthenticatedRoute = () => {
  const { account } = useWallet();
  return account ? <Navigate to="/dashboard" /> : <Outlet />;
};

export { AuthenticatedRoute, UnauthenticatedRoute };
