import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from "@apollo/client";
import { UserExists } from './graphql/query/UserExist';
import {
  TextField, Button, Box, FormControlLabel, Radio, RadioGroup,
  FormControl, FormLabel, Container, Typography, Grid, Paper, IconButton
} from '@mui/material';
import { styled } from '@mui/system';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Login from './Login';
import { useWallet } from '@alephium/web3-react';
import { CREATE_USER, CREATE_ORGANIZATION } from './graphql/mutations/createEntity';
import toast from 'react-hot-toast';
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'
import { Envs } from './conf/envs';
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(5),
  background: 'rgba(255, 255, 255, 0.2)',
  borderRadius: '10px',
  color: 'white',
  boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)',
  width: '400px', // Fixed width
  height: 'auto', // Auto height to fit content
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .highlight': {
    fontWeight: 'bold',
    color: 'yellow', // Highlight color
  },
}));
const HighlightedAddressInput = ({ value }) => {
  const addressParts = value.split("");

  const highlightedValue = addressParts.map((char, index) => {
    if (index < 4 || index >= value.length - 4) {

      return (
        <span style={{ color: "#007bff" }}>{char}</span>
      );
    }
    if (index > 27 && index < 30) return (<span style={{ color: "yellow" }}>..</span>)
    if (index > 30 && index < 43) return (<span style={{ color: "#007bff" }}></span>)
    return <span style={{ color: "white" }}>{char}</span>;
  });

  return <div >{highlightedValue}</div>;
};
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: 'white',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: 'white',
  '& .MuiRadio-root': {
    color: 'white',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: 'white',
  borderColor: 'white',
  marginTop: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Dashboard = ({ userType, onSwitch }) => {
  const { width, height } = useWindowSize()
  const notify = (message) => toast(message);
  const { account } = useWallet();
  const { address } = account || {};
  const [exists, setExists] = useState(false);
  
  const { loading, error, data ,refetch} = useQuery(UserExists, {
    variables: { address },
    skip: !address,
    onCompleted: (data) => {
      setExists(data.userExists);
    },
  });
 

  const [createUser] = useMutation(CREATE_USER);
  const [createOrganization] = useMutation(CREATE_ORGANIZATION);

  const [formData, setFormData] = useState({
    name: '',
    ownerAddress: address || '',
    token: '',
    minToken: 0,
    isStaked: 'false',
    gatedBot: 'false',
    multiPost: 'false',
  });

  useEffect(() => {
    if (address) {
      setFormData((prevData) => ({
        ...prevData,
        ownerAddress: address,
      }));
    }
  }, [address]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Function to wait for the token to be set in localStorage
    const waitForToken = async () => {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const token = localStorage.getItem(Envs.TOKEN_NAME); // Replace 'token' with the actual key name
          if (token) {
            clearInterval(interval);
            resolve(token);
          }
        }, 100); // Check every 100ms
      });
    };
  
    let token;
    if (userType === 'org' && formData.gatedBot === 'true') {
      token = await waitForToken(); // Wait until the token is set in localStorage
    } else {
      token = localStorage.getItem(Envs.TOKEN_NAME); // Just retrieve the token
    }
  
    try {
      const mutation = userType === 'org' ? createOrganization : createUser;
      const inpt = userType === "org" ? {
        name: formData.name,
        address: formData.ownerAddress,
        token: formData.token,
        minToken: parseInt(formData.minToken),
        isStaked: formData.isStaked === 'true',
        gatedBot: formData.gatedBot === 'true',
        multiPost: formData.multiPost === 'true'
      } : { address: formData.ownerAddress };
      
      const response = await mutation({
        variables: {
          input: inpt
        }
      });
  
      if (response.data) {
        refetch();
        notify(`${userType === 'org' ? 'Org' : 'User'} account created successfully!`);
      } else {
        notify("There was an undetected error creating your account, please contact support.");
      }
    } catch (error) {
     
      notify(error.message);
    }
  };
  
  const highlightAddress = (address) => {
    if (!address) return '';
    const start = `<span class="highlight">${address.slice(0, 4)}</span>`;
    const end = `<span class="highlight">${address.slice(-4)}</span>`;
    const middle = address.slice(4, -4);
    return `${start}${middle}${end}`;
  };
  if(!!data?.userExists) return (
    <>
    <Confetti
    width={width}
    height={height}
  />
    <Grid container direction="column" width="100vw" height="100vh" justifyContent="center" alignItems="center">
      <Grid item>
      <Typography variant="h2" fontSize="10vw">🎉 Good News 🎉 </Typography>
      </Grid>
      <Grid item>
      <Typography variant="h4"> You Already Have An Account </Typography>
      </Grid>
      <Grid item>
      <a href="https://app.alphpost.com" target="_blank" rel="noopener noreferrer">
    <Button>Go To Dapp</Button>
</a>
     </Grid>
      </Grid>
      </>
  )
  if(!data?.userExists)
  return (
    <>
      <Login />
      <Grid container direction="row" width="100vw" height="100vh" justifyContent="center" alignItems="center">
        <Grid item>
          <Container maxWidth="sm">
            <StyledPaper elevation={3}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" gutterBottom align="center">
                  {userType === 'org' ? 'Create Org' : 'Create User'}
                </Typography>
                <IconButton onClick={onSwitch} color="inherit">
                  <SwapHorizIcon />
                </IconButton>
              </Box>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      label="Owner Address"
                      name="ownerAddress"
                      value={formData.ownerAddress}
                      height="20vh"
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                        inputComponent: HighlightedAddressInput,
                      }}
                    />
                  </Grid>
                  {userType === 'org' && (
                    <>
                      <Grid item xs={12}>
                        <StyledTextField
                          fullWidth
                          label="Name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                          <StyledFormLabel component="legend">Gated Bot</StyledFormLabel>
                          <RadioGroup
                            row
                            name="gatedBot"
                            value={formData.gatedBot}
                            onChange={handleChange}
                          >
                            <StyledFormControlLabel value="true" control={<Radio />} label="Yes" />
                            <StyledFormControlLabel value="false" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {formData.gatedBot === 'true' && (
                        <>
                          <Grid item xs={12}>
                            <Box mt={2} mb={2}>
                              <Typography variant="h6" align="center">Gated Bot Details</Typography>
                            </Box>
                            <StyledTextField
                              fullWidth
                              label="Token"
                              name="token"
                              value={formData.token}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <StyledTextField
                              fullWidth
                              label="Min Token"
                              name="minToken"
                              type="number"
                              value={formData.minToken}
                              onChange={handleChange}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                              <StyledFormLabel component="legend">Is Staked</StyledFormLabel>
                              <RadioGroup
                                row
                                name="isStaked"
                                value={formData.isStaked}
                                onChange={handleChange}
                              >
                                <StyledFormControlLabel value="true" control={<Radio />} label="Yes" />
                                <StyledFormControlLabel value="false" control={<Radio />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <FormControl component="fieldset" fullWidth>
                          <StyledFormLabel component="legend">Multi Post</StyledFormLabel>
                          <RadioGroup
                            row
                            name="multiPost"
                            value={formData.multiPost}
                            onChange={handleChange}
                          >
                            <StyledFormControlLabel value="true" control={<Radio />} label="Yes" />
                            <StyledFormControlLabel value="false" control={<Radio />} label="No" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <StyledButton type="submit" variant="outlined" fullWidth>
                      {userType === 'org' ? 'Create Org' : 'Create User'}
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            </StyledPaper>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
const SelectionScreen = ({ onSelection }) => {
  const handleSelection = (value) => {
    localStorage.setItem('userType', value);
    onSelection(value);
  };

  return (
    <Grid container direction="row" width="100vw" height="100vh" justifyContent="center" alignItems="center">
      <Grid item>
        <Container maxWidth="sm">
          <StyledPaper elevation={3}>
            <Typography variant="h4" gutterBottom align="center">Select Option</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledButton
                  variant="outlined"
                  fullWidth
                  onClick={() => handleSelection('user')}
                >
                  I'm a User
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  variant="outlined"
                  fullWidth
                  onClick={() => handleSelection('org')}
                >
                  I'm an Org
                </StyledButton>
              </Grid>
            </Grid>
          </StyledPaper>
        </Container>
      </Grid>
    </Grid>
  );
};

const App = () => {
  const [userType, setUserType] = useState(localStorage.getItem('userType') || '');

  const handleSelection = (value) => {
    setUserType(value);
  };

  const handleSwitch = () => {
    const newType = userType === 'user' ? 'org' : 'user';
    localStorage.setItem('userType', newType);
    setUserType(newType);
  };

  if (!userType) {
    return <SelectionScreen onSelection={handleSelection} />;
  }

  return <Dashboard userType={userType} onSwitch={handleSwitch} />;
};

export default App;
